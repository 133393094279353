<template>
  <div class="flex min-h-screen flex-col text-txt">
    <Colors :colors="colors" />
    <Header :logo-caption="logoCaption!" :phone="salesoffice?.phone" iserror />
    <slot v-if="precheckin" />
    <SomeError v-else type="precheckins" />
    <Footer
      :footer-links="navigation?.links"
      class="print:hidden"
      :logo-caption="logoCaption"
    />
  </div>
</template>

<script lang="ts" setup>
const { logoCaption, salesoffice, navigation } = useConfdata()
const { colors } = useColors()
const { precheckin } = usePrecheckin()

if (!usePrecheckin().precheckin) {
  useHead({ title: 'Token Error' })
}
</script>

<style scoped></style>
